<template>
  <b-tab :title="$t('ASSINATURAS.ABA_PEDIDOS')" class="p-0">
    <b-card>
      <div>
        <div class="d-flex align-items-center mb-2">
          <template v-if="podeCancelar">
            <b-button
              id="btn-aprovar-cancelamento"
              @click="abrirModalCancelamento(true)"
              variant="outline-valorem-ciano"
              class="mr-2"
            >
              <div class="d-flex align-items-center">
                <i class="fas fa-thumbs-up mr-2"></i>
                Aprovar cancelamento
              </div>
            </b-button>
            <b-button
              id="btn-rejeitar-cancelamento"
              @click="abrirModalCancelamento(false)"
              variant="outline-valorem-vermelho"
              class="mr-3"
            >
              <div class="d-flex align-items-center">
                <i class="fas fa-thumbs-down mr-2"></i>
                Rejeitar cancelamento
              </div>
            </b-button>
          </template>
          <b-button
            id="btn-visualizar"
            @click="abrirModalSolicitacao()"
            v-if="podeVerSolicitacao"
            class="mr-2"
            variant="valorem-ciano"
          >
            {{ $t("SOLICITACOES.VER_SOLICITACOES") }}
          </b-button>
        </div>
        <valorem-tabela
          id="tabela-solicitacoes"
          sem-selecionar-todos
          v-model="tabela.dados"
          :colunas="tabela.colunas"
          ref="tabela"
        >
          <template #cell(tipoSolicitacao)="dados">
            <span
              :class="`text-${dados.value?.variante} font-weight-bold text-capitalize`"
            >
              {{ dados.value?.text }}
            </span>
          </template>
          <template #cell(statusSolicitacao)="dados">
            <span
              :class="`text-${dados.value?.variante} font-weight-bold text-capitalize`"
            >
              {{ dados.value?.text }}
            </span>
          </template>
        </valorem-tabela>
      </div>
    </b-card>

    <modal-cancelamento-assinatura ref="modalAssinatura" />
    <modal-cancelamento-cobranca ref="modalCobranca" @atualizar="atualizar" />
  </b-tab>
</template>
<script>
import helpers from "@/common/utils/helpers";
import ModalCancelamentoAssinatura from "./ModalCancelamentoAssinatura";
import ModalCancelamentoCobranca from "./ModalCancelamentoCobranca.vue";
import AssinaturasService from "@/common/services/assinaturas/assinaturas.service";
export default {
  props: {
    form: {
      required: true,
    },
  },
  components: {
    ModalCancelamentoAssinatura,
    ModalCancelamentoCobranca,
  },
  data: function () {
    return {
      tabela: {
        dados: [],
        colunas: [
          { key: "seletor", label: "" },
          {
            key: "tipoSolicitacao",
            label: this.$t("SOLICITACOES.TABELA.TIPO_SOLICITACAO"),
            formatter: (v) => {
              if (v) return helpers.getEnum(v);
            },
          },
          {
            key: "numeroParcela",
            label: this.$t("SOLICITACOES.TABELA.PARCELA"),
          },
          {
            key: "dataSolicitacao",
            label: this.$t("SOLICITACOES.TABELA.DATA_SOLICITACAO"),
            formatter: (v) => {
              if (v) return helpers.formatarDataBr(v.dataSolicitacao, true);
            },
          },
          { key: "motivo", label: this.$t("SOLICITACOES.TABELA.MOTIVO_CANCELAMENTO") },
          {
            key: "dataResposta",
            label: this.$t("SOLICITACOES.TABELA.DATA_PARECER"),
            formatter: (v) => {
              if (v) return helpers.formatarDataBr(v.dataSolicitacao, true);
            },
          },
          { key: "respostaValorem", label: this.$t("SOLICITACOES.TABELA.PARECER") },
          {
            key: "statusSolicitacao",
            label: "",
            formatter: (v) => {
              return helpers.getEnum(v);
            },
          },
        ],
      },
    };
  },
  mounted() {},
  methods: {
    abrirModalCancelamento: function (aprovar) {
      let solicitacao = this.dadosSelecionados[0];
      if (solicitacao.tipoSolicitacao == "Assinatura") {
        this.$refs.modalAssinatura.abrirModal(this.form.id, solicitacao, aprovar);
      } else {
        this.$refs.modalCobranca.abrirModal({}, solicitacao, aprovar);
      }
    },
    abrirModalSolicitacao: function () {
      let solicitacao = this.dadosSelecionados[0];
      if (solicitacao.tipoSolicitacao == "Assinatura") {
        this.$refs.modalAssinatura.abrirModalDesabilitado(this.form.id, solicitacao);
      } else {
        this.$refs.modalCobranca.abrirModalDesabilitado({}, solicitacao);
      }
    },
    buscarSolicitacoes: function () {
      AssinaturasService.buscarSolicitacoes(this.form.id).then((res) => {
        this.tabela.dados = res.data.data.solicitacoes;
      });
    },
    atualizar: function () {
      this.$refs.modalCobranca.fecharModal();
      this.buscarSolicitacoes();
      this.$emit("atualizar");
    },
  },
  computed: {
    ehAdmin: function () {
      return this.$store.getters.ehAdmin;
    },
    dadosSelecionados: function () {
      return this.tabela.dados.filter((el) => {
        return el.selecionado;
      });
    },
    podeCancelar: function () {
      if (this.dadosSelecionados.length != 1) return false;

      return this.dadosSelecionados[0].statusSolicitacao == "Pendente" && this.ehAdmin;
    },
    podeVerSolicitacao: function () {
      if (this.dadosSelecionados.length != 1) return false;
      return true;
    },
  },
  watch: {
    "form.id": {
      handler() {
        this.buscarSolicitacoes();
      },
    },
  },
};
</script>
<style scoped lang="scss">
.legenda {
  width: 15px;
  height: 15px;
  border-radius: 50%;
}
h6 {
  margin-bottom: 0px;
}
.card-bordered {
  border-width: 1px !important;
}
</style>
