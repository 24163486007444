<template>
  <b-tab :title="$t('ASSINATURAS.ABA_HISTORICO_TRANSACAO')" class="p-0">
    <div class="row">
      <b-card class="col-12">
        <valorem-tabela
          sem-selecionar-todos
          v-model="form.historicoTransacaoStatus"
          :colunas="tabela.colunas"
          ref="tabela"
        >
        </valorem-tabela>
      </b-card>
    </div>
  </b-tab>
</template>
<script>
import helpers from "@/common/utils/helpers";
export default {
  props: {
    form: {},
  },
  data: function () {
    return {
      tabela: {
        colunas: [
          {
            key: "parcelas",
            label: this.$t("ASSINATURAS.HISTORICO.PARCELA"),
          },
          {
            key: "dataEfetivacao",
            label: this.$t("ASSINATURAS.HISTORICO.DATA_COBRANCA"),
          },
          { key: "nsuHost", label: this.$t("ASSINATURAS.HISTORICO.NSU") },
          {
            key: "numeroAutorizacao",
            label: this.$t("ASSINATURAS.HISTORICO.COD_AUTORIZACAO"),
          },
          {
            key: "bandeira",
            label: this.$t("ASSINATURAS.HISTORICO.BANDEIRA"),
            formatter : (v) => {if(v) return v}
          },
          {
            key: "cartaoAssinante",
            label: this.$t("ASSINATURAS.HISTORICO.NUMERO_CARTAO"),
          },
          {
            key: "valorTransacao",
            label: this.$t("ASSINATURAS.HISTORICO.VALOR_TOTAL"),
            formatter: (v) => {
              return helpers.formatarValor(v);
            },
          },
          {
            key: "status",
            label: this.$t("ASSINATURAS.HISTORICO.SITUACAO"),
            formatter: (v) => {
              if (v) return helpers.getEnum(v)?.text;
            },
          },
          { key: "mensagem", label: this.$t("ASSINATURAS.HISTORICO.MENSAGEM") },
        ],
        paginaAtual: 0,
        porPagina: 0,
      },
    };
  },
  mounted() {},
  methods: {},
};
</script>
<style lang=""></style>
