<template>
  <valorem-modal
    ref="modal"
    :titulo-modal="tituloModal"
    @ok="salvar"
    :titulo-ok="tituloBotaoOk"
    :ok-desabilitado="desabilitado"
  >
    <div class="row">
      <input-text
        id="input-nsu"
        disabled
        class="col-12 col-md-3"
        :label="$t('SOLICITACOES.CANCELAMENTO_COBRANCA.NSU')"
        v-model="form.NSU"
        ref="form-nsu"
      />
      <input-date
        id="input-data-venda"
        disabled
        class="col-12 col-md-3"
        :label="$t('SOLICITACOES.CANCELAMENTO_COBRANCA.DATA_VENDA')"
        v-model="form.dataCobranca"
        ref="form-data"
      />
    </div>
    <div class="row">
      <input-currency
        id="input-valor-venda"
        disabled
        class="col-12 col-md-3"
        :label="$t('SOLICITACOES.CANCELAMENTO_COBRANCA.VALOR_VENDA')"
        v-model="form.valorVenda"
        ref="form-valorVenda"
      />
      <input-currency
        id="input-valor-disponivel"
        disabled
        class="col-12 col-md-3"
        :label="$t('SOLICITACOES.CANCELAMENTO_COBRANCA.VALOR_DISPONIVEL')"
        v-model="form.valorDisponivel"
        ref="form-valorDisponivel"
      />
      <input-select
        id="input-opcao-cancelamento"
        :disabled="form.solicitacaoId != null || desabilitado"
        class="col-12 col-md-3"
        :label="$t('SOLICITACOES.CANCELAMENTO_COBRANCA.OPCAO_CANCELAMENTO')"
        :options="opcoes.cancelamento"
        v-model="form.tipoCancelamentoCobranca"
        ref="form-tipoCancelamentoCobranca"
        required
      />
      <input-currency
        id="input-valor-cobrado"
        :disabled="
          form.tipoCancelamentoCobranca != 'Parcial' ||
          form.solicitacaoId != null ||
          desabilitado
        "
        class="col-12 col-md-3"
        :label="$t('SOLICITACOES.CANCELAMENTO_COBRANCA.VALOR_COBRADO')"
        v-model="valorACancelar"
        ref="form-valorCobrado"
        required
      />

      <input-text-area
        id="input-motivo"
        :disabled="form.solicitacaoId != null || desabilitado"
        class="col-12"
        :label="$t('SOLICITACOES.MOTIVO_CANCELAMENTO')"
        v-model="form.motivoCancelamento"
        ref="form-motivo"
        required
      />

      <input-text-area
        id="input-resposta"
        :disabled="desabilitado"
        v-if="form.solicitacaoId != null"
        class="col-12"
        :label="$t('SOLICITACOES.RESPOSTA_VALOREM')"
        v-model="form.respostaValorem"
        ref="form-motivo"
        required
      />
    </div>
  </valorem-modal>
</template>
<script>
import {
  InputText,
  InputDate,
  InputCurrency,
  InputSelect,
  InputTextArea,
} from "@/components/inputs";
import cloneDeep from "lodash.clonedeep";
import helpers from "../../../common/utils/helpers";
import AssinaturaCobrancasService from "@/common/services/assinatura_cobrancas/assinatura_cobrancas.service";
import { START_LOADING, STOP_LOADING } from "@/store/Store";
export default {
  components: {
    InputText,
    InputDate,
    InputCurrency,
    InputSelect,
    InputTextArea,
  },
  data() {
    return {
      opcoes: {
        cancelamento: helpers.TipoCancelamentoCobranca,
      },
      form: {
        aprovar: false,
        dataCobranca: "",
        motivoCancelamento: "",
        solicitacaoId: "",
        assinaturaCobrancaId: "",
        cobrancaId: "",
        respostaValorem: null,
        NSU: "",
        valorACancelar: 10,
        valorDisponivel: 0,
        valorVenda: 0,
        tipoCancelamentoCobranca: "Total",
      },
      desabilitado: false,
    };
  },
  methods: {
    abrirModalDesabilitado: function (form = {}, solicitacao) {
      this.abrirModal(form, solicitacao, false, true);
    },
    abrirModal: function (
      form = {},
      solicitacao,
      aprovar = false,
      desabilitado = false
    ) {
      this.desabilitado = desabilitado;
      if (solicitacao) {
        this.form.aprovar = aprovar;
        this.form.dataCobranca = solicitacao.dataCobranca;
        this.form.motivoCancelamento = solicitacao.motivo;
        this.form.solicitacaoId = solicitacao.id;
        this.form.assinaturaCobrancaId = solicitacao.assinaturaCobrancaId;
        this.form.cobrancaId = solicitacao.assinaturaCobrancaId;
        this.form.respostaValorem = solicitacao.respostaValorem;
        this.form.NSU = solicitacao.nsu;
        this.form.valorACancelar = solicitacao.valorACancelar;
        this.form.valorDisponivel = helpers.formatarValor(
          solicitacao.valorDisponivel
        );
        this.form.valorVenda = helpers.formatarValor(solicitacao.valorVenda);
        this.form.tipoCancelamentoCobranca =
          solicitacao.tipoCancelamentoCobranca;
      } else {
        this.form = {
          dataCobranca: form.dataCobranca,
          valorDisponivel: helpers.formatarValor(form.valor),
          valorVenda: helpers.formatarValor(form.valor),
          NSU: form.nsu,
          assinaturaCobrancaId: form.id,
          cobrancaId: form.id,
          motivoCancelamento: "",
          tipoCancelamentoCobranca: "Parcial",
          valorACancelar: 0,
        };
      }
      this.$refs.modal.abrirModal();
    },
    fecharModal: function () {
      this.$refs.modal.fecharModal();
    },
    salvar: function () {
      if (!this.validarFormulario("form-")) return;

      let tituloConfirmar =
        "SOLICITACOES.CANCELAMENTO_COBRANCA.TITULO_CONFIRMAR_PEDIDO_CANCELAMENTO";
      if (this.temCancelamentoAutomatico)
        tituloConfirmar =
          "SOLICITACOES.CANCELAMENTO_COBRANCA.TITULO_CONFIRMAR_PEDIDO_CANCELAMENTO_AUTOMATICO";

      let mensagemConfirmar = "SOLICITACOES.CANCELAMENTO_COBRANCA.CONFIRMAR_CANCELAMENTO";
      if (this.form.solicitacaoId != null)
        mensagemConfirmar = `SOLICITACOES.CANCELAMENTO_COBRANCA.${
          this.form.aprovar ? "APROVAR" : "REJEITAR"
        }.CONFIRMAR_CANCELAMENTO`;
      this.confirmar(this.$t(tituloConfirmar), this.$t(mensagemConfirmar)).then((confirmado) => {
        if (!confirmado) return;

        let form = cloneDeep(this.form);
        if (this.form.tipoCancelamentoCobranca != "Parcial")
          form.valorACancelar = form.valorDisponivel;

        if(form.dataCobranca.indexOf('/') > -1)
          form.dataCobranca = helpers.formatarDataBd(form.dataCobranca);
          
        form.valorACancelar = helpers.removerValor(`${form.valorACancelar}`);
        form.valorDisponivel = helpers.removerValor(`${form.valorDisponivel}`);
        form.valorVenda = helpers.removerValor(`${form.valorVenda}`);

        this.$store.dispatch(START_LOADING);
        let api =
          this.form.solicitacaoId != null
            ? AssinaturaCobrancasService.responderSolicitacaoCancelamento
            : AssinaturaCobrancasService.solicitarCancelamento;
        api(form)
          .then(() => {
            let mensagem = "SOLICITACOES.CANCELAMENTO_COBRANCA.SUCESSO";
            if (this.temCancelamentoAutomatico)
              mensagem =
                "SOLICITACOES.CANCELAMENTO_COBRANCA.SUCESSO_AUTOMATICO";

            if (this.form.solicitacaoId != null)
              mensagem = `SOLICITACOES.CANCELAMENTO_COBRANCA.${
                this.form.aprovar ? "APROVAR" : "REJEITAR"
              }.SUCESSO`;

            this.alertaSucesso(this.$t(mensagem)).then(() => {
              this.$emit("atualizar");
            });
          })
          .finally(() => {
            this.$store.dispatch(STOP_LOADING);
          });
      });
    },
  },
  computed: {
    valorACancelar: {
      set: function (val) {
        this.form.valorACancelar = val;
      },
      get: function () {
        return this.form.tipoCancelamentoCobranca == "Parcial"
          ? this.form.valorACancelar
          : this.form.valorDisponivel;
      },
    },
    temCancelamentoAutomatico: function () {
      return this.$store.getters.temCancelamentoAutomatico;
    },
    tituloBotaoOk: function () {
      if (this.desabilitado) return "...";

      if (this.form.solicitacaoId != null)
        return this.$t(
          `SOLICITACOES.CANCELAMENTO_COBRANCA.${
            this.form.aprovar ? "APROVAR" : "REJEITAR"
          }.BOTAO_CANCELAMENTO`
        );

      if (this.temCancelamentoAutomatico)
        return this.$t(
          "SOLICITACOES.CANCELAMENTO_COBRANCA.BOTAO_SOLICITAR_CANCELAMENTO_AUTOMATICO"
        );

      return this.$t(
        "SOLICITACOES.CANCELAMENTO_COBRANCA.BOTAO_SOLICITAR_CANCELAMENTO"
      );
    },
    tituloModal: function () {
      if (this.desabilitado)
        return this.$t(
          "SOLICITACOES.CANCELAMENTO_COBRANCA.TITULO_MODAL_VISUALIZAR"
        );

      if (this.form.solicitacaoId != null)
        return this.$t(
          `SOLICITACOES.CANCELAMENTO_COBRANCA.${
            this.form.aprovar ? "APROVAR" : "REJEITAR"
          }.BOTAO_CANCELAMENTO`
        );

      if (this.temCancelamentoAutomatico)
        return this.$t(
          "SOLICITACOES.CANCELAMENTO_COBRANCA.TITULO_MODAL_AUTOMATICO"
        );

      return this.$t("SOLICITACOES.CANCELAMENTO_COBRANCA.TITULO_MODAL");
    },
  },
};
</script>
<style lang=""></style>
