<template>
  <b-tab :title="$t('ASSINATURAS.ABA_COBRANCAS')" class="p-0">
    <div class="row">
      <b-card class="col-12">
        <div class="flex-responsive">
          <div class="d-flex flex-column mr-5">
            <small class="font-weight-bold text-valorem-grafite">{{
              $t("ASSINATURAS.VALOR_PAGO")
            }}</small>
            <h5 class="text-valorem-azul-naval">{{ valorPago }}</h5>
          </div>
          <div class="d-flex flex-column mr-5">
            <small class="font-weight-bold text-valorem-grafite">{{
              $t("ASSINATURAS.VALOR_A_RECEBER")
            }}</small>
            <h5 class="text-valorem-dourado">{{ valorAReceber }}</h5>
          </div>
          <div class="d-flex flex-column">
            <small class="font-weight-bold text-valorem-grafite">{{
              $t("ASSINATURAS.VALOR_EM_ATRASO")
            }}</small>
            <h5 class="text-valorem-vermelho">{{ valorEmAtraso }}</h5>
          </div>
        </div>
        <div class="d-flex align-items-center mb-2">
          <b-button
            id="btn-solicitar-cancelamento"
            @click="abrirModalCancelamento(null)"
            v-if="podeSolicitarCancelamento"
            class="mr-2"
            variant="outline-valorem-vermelho"
          >
            {{ $t("ASSINATURAS.SOLICITAR_CANCELAMENTO") }}
          </b-button>

          <b-button
            id="btn-forcar-consulta"
            @click="forcarConsultaStatus(null)"
            v-if="podeSolicitarForcarConsultaStatus"
            class="mr-2"
            variant="primary"
          >
            {{ $t("ASSINATURAS.CONSULTA_STATUS_ESITEF") }}
          </b-button>

          <b-button
            id="btn-recomprar-cobranca"
            @click="recomprarCobranca(null)"
            v-if="podeRecomprarCobranca"
            class="mr-2"
            variant="primary"
          >
            {{ $t("ASSINATURAS.CONSULTA_STATUS_ESITEF") }}
          </b-button>

          <template v-if="podeResponder">
            <b-button
              id="btn-aprovar-cancelamento"
              @click="abrirModalCancelamento(true)"
              variant="outline-valorem-ciano"
              class="mr-2"
            >
              <div class="d-flex align-items-center">
                <i class="fas fa-thumbs-up mr-2"></i>
                Aprovar cancelamento
              </div>
            </b-button>
            <b-button
              id="btn-rejeitar-cancelamento"
              @click="abrirModalCancelamento(false)"
              variant="outline-valorem-vermelho"
              class="mr-3"
            >
              <div class="d-flex align-items-center">
                <i class="fas fa-thumbs-down mr-2"></i>
                Rejeitar cancelamento
              </div>
            </b-button>
          </template>
          <b-button
            id="btn-retentar-pagamento"
            @click="pagamentoManual()"
            variant="valorem-ciano"
            class="mr-3"
            v-if="podeRetentar"
          >
            <div class="d-flex align-items-center">
              Retentativa de pagamento manual
            </div>
          </b-button>
        </div>
        <valorem-tabela
          id="tabela-cobrancas"
          sem-selecionar-todos
          :metodo-validacao-selecionar="metodoValidacaoSelecionar"
          v-model="form.cobrancas"
          :colunas="tabela.colunas"
          ref="tabela"
          rodape
        >
          <template #cell(situacao)="dados">
            <span
              :class="`text-${dados.value?.variante} font-weight-bold text-capitalize`"
            >
              {{ dados.value?.text }}
            </span>
          </template>

          <template #foot(valor)>
            <div>
              {{ totalValor }}
            </div>
          </template>
          <template #foot(valorProcessamento)>
            <div>
              {{ totalValorProcessamento }}
            </div>
          </template>
          <template #foot(valorLiquidoAntecipacao)>
            <div>
              {{ totalValorAntecipado }}
            </div>
          </template>
          <template #foot(qProfValorDesagio)>
            <div>
              {{ totalQProfValorDesagio }}
            </div>
          </template>
          <template #foot(qProfValorTarifa)>
            <div>
              {{ totalQProfValorTarifa }}
            </div>
          </template>
          <template #foot(qProfValorAquisicao)>
            <div>
              {{ totalQProfValorAquisicao }}
            </div>
          </template>
          <template #foot()>
            <span></span>
          </template>
        </valorem-tabela>
      </b-card>
      <modal-cancelamento-cobranca ref="modal" @atualizar="atualizar" />
    </div>
  </b-tab>
</template>
<script>
import helpers from "@/common/utils/helpers";
import cloneDeep from "lodash.clonedeep";
import ModalCancelamentoCobranca from "./ModalCancelamentoCobranca.vue";
import AssinaturaCobrancasService from "@/common/services/assinatura_cobrancas/assinatura_cobrancas.service";
import { START_LOADING, STOP_LOADING } from "@/store/Store";
export default {
  props: {
    form: {
      required: true,
    },
  },
  components: {
    ModalCancelamentoCobranca,
  },
  data: function () {
    return {
      tabela: {
        colunas: [
          { key: "seletor", label: "" },
          {
            key: "numeroParcela",
            label: this.$t("ASSINATURAS.COBRANCAS.PARCELA"),
          },
          {
            key: "dataCobranca",
            label: this.$t("ASSINATURAS.COBRANCAS.DATA_COBRANCA"),
            formatter: (v) => {
              if (v) return helpers.formatarDataBr(v);
            },
          },
          {
            key: "dataVencimento",
            label: this.$t("ASSINATURAS.COBRANCAS.DATA_VENCIMENTO"),
            formatter: (v) => {
              if (v) return helpers.formatarDataBr(v);
            },
          },
          { key: "nsu", label: this.$t("ASSINATURAS.COBRANCAS.NSU") },
          {
            key: "codigoAutorizacao",
            label: this.$t("ASSINATURAS.COBRANCAS.COD_AUTORIZACAO"),
          },
          {
            key: "valor",
            label: this.$t("ASSINATURAS.COBRANCAS.VALOR_TOTAL"),
            formatter: (v) => {
              return helpers.formatarValor(v || 0);
            },
          },
          {
            key: "situacaoAntecipacao",
            label: this.$t("ASSINATURAS.COBRANCAS.ANTECIPACAO"),
            formatter: (v) => {
              if (v) return helpers.getEnum(v)?.text;
            },
          },
          {
            key: "valorProcessamento",
            label: this.$t("ASSINATURAS.COBRANCAS.VALOR_PROCESSADO"),
            formatter: (v) => {
              return helpers.formatarValor(v || 0);
            },
          },
          {
            key: "valorLiquidoAntecipacao",
            label: this.$t("ASSINATURAS.COBRANCAS.VALOR_ANTECIPADO"),
            formatter: (v) => {
              return helpers.formatarValor(v || 0);
            },
          },
          {
            key: "qProfValorDesagio",
            label: this.$t("ASSINATURAS.COBRANCAS.VALOR_DESAGIO"),
            formatter: (v) => {
              return helpers.formatarValor(v || 0);
            },
          },
          {
            key: "qProfValorTarifa",
            label: this.$t("ASSINATURAS.COBRANCAS.VALOR_TARIFA"),
            formatter: (v) => {
              return helpers.formatarValor(v || 0);
            },
          },
          {
            key: "qProfValorAquisicao",
            label: this.$t("ASSINATURAS.COBRANCAS.VALOR_LIQUIDO"),
            formatter: (v) => {
              return helpers.formatarValor(v || 0);
            },
          },
          {
            key: "situacao",
            label: this.$t("ASSINATURAS.COBRANCAS.SITUACAO"),
            formatter: (v) => {
              if (v) return helpers.getEnum(v);
            },
          },
        ],
        paginaAtual: 0,
        porPagina: 0,
      },
    };
  },
  mounted() {},
  methods: {
    metodoValidacaoSelecionar: function () {
      return true;
    },
    abrirModalCancelamento: function (aprovar = null) {
      if (aprovar == null) {
        this.$refs.modal.abrirModal(cloneDeep(this.dadosSelecionados[0]));
      } else {
        this.$store.dispatch(START_LOADING);
        AssinaturaCobrancasService.buscarSolicitacao(
          this.dadosSelecionados[0].id
        )
          .then((res) => {
            this.$refs.modal.abrirModal({}, res.data.data, aprovar);
          })
          .finally(() => {
            this.$store.dispatch(STOP_LOADING);
          });
      }
    },
    pagamentoManual() {
      this.confirmar(
        "Retentativa de pagamento manual",
        this.$t("GERAL.MENSAGEM_CONFIRMAR_ACAO")
      ).then((confirmado) => {
        if (!confirmado) return;

        this.$store.dispatch(START_LOADING);
        AssinaturaCobrancasService.retentarPagamento(
          this.dadosSelecionados[0].id
        )
          .then(() => {
            this.alertaSucesso("Pagamento efetuado com sucesso");
            this.$emit("atualizar");
          })
          .finally(() => {
            this.$store.dispatch(STOP_LOADING);
          });
      });
    },
    atualizar: function () {
      this.$emit("atualizar");
      this.$refs.modal.fecharModal();
    },
    forcarConsultaStatus: function () {
      let idSelecionado = this.dadosSelecionados[0].id;

      this.confirmar(
        this.$t("ASSINATURAS.TITULO_CONSULTA_STATUS_ESITEF"),
        this.$t(`GERAL.MENSAGEM_CONFIRMAR_ACAO`),
        this.$t("ASSINATURAS.CONSULTA_STATUS_ESITEF"),
        this.$t(`GERAL.CANCELAR`)
      ).then((confirmado) => {
        if (!confirmado) return;

        this.$store.dispatch(START_LOADING);
        AssinaturaCobrancasService.forcarAtualizacaoStatusCobrancaParcelada(
          idSelecionado
        )
          .then(() => {
            this.toastSucesso("Consulta de status efetuada com sucesso");
            this.$emit("atualizar");
          })
          .finally(() => {
            this.$store.dispatch(STOP_LOADING);
          });
      });
    },
    recomprarCobranca: function () {
      let idSelecionado = this.dadosSelecionados[0].id;

      this.confirmar(
        this.$t(`ASSINATURAS.TITULO_RECOMPRAR`),
        this.$t("GERAL.MENSAGEM_CONFIRMAR_ACAO")
      ).then((confirmado) => {
        if (!confirmado) return;
        this.$store.dispatch(START_LOADING);
        AssinaturaCobrancasService.recomprarCobranca(idSelecionado)
          .then(() => {
            this.alertaSucesso(
              this.$t("ASSINATURAS.MENSAGEM_SUCESSO_RECOMPRA")
            ).then(() => {
              this.listar();
            });
          })
          .finally(() => {
            this.$store.dispatch(START_LOADING);
          });
      });
    },
  },
  computed: {
    permissao: function () {
      return helpers.validarAcesso("Assinatura");
    },
    dadosSelecionados: function () {
      return this.form.cobrancas?.filter((el) => {
        return el.selecionado;
      });
    },
    valorPago: function () {
      return helpers.formatarValor(
        this.form.cobrancas
          .filter((cobranca) => cobranca.situacao == "Aprovado")
          .reduce((total, cobranca) => {
            return total + cobranca.valor;
          }, 0)
      );
    },
    valorAReceber: function () {
      return helpers.formatarValor(
        this.form.cobrancas
          .filter(
            (cobranca) =>
              cobranca.situacao == "Pendente" &&
              new Date(cobranca.dataVencimento).setHours(0, 0, 0, 0) >=
                new Date().setHours(0, 0, 0, 0)
          )
          .reduce((total, cobranca) => {
            return total + cobranca.valor;
          }, 0)
      );
    },
    valorEmAtraso: function () {
      return helpers.formatarValor(
        this.form.cobrancas
          .filter(
            (cobranca) =>
              cobranca.situacao == "Negado" ||
              (cobranca.situacao == "Pendente" &&
                new Date(cobranca.dataVencimento).setHours(0, 0, 0, 0) <=
                  new Date().setHours(0, 0, 0, 0))
          )
          .reduce((total, cobranca) => {
            return total + cobranca.valor;
          }, 0)
      );
    },
    podeSolicitarCancelamento: function () {
      if (this.dadosSelecionados.length != 1) return false;

      return this.dadosSelecionados[0].situacao == "Aprovado";
    },
    podeAntecipar: function () {
      if (this.dadosSelecionados.length == 0) return false;

      return (
        this.form.liberarAntecipacaoRecorrencia &&
        this.dadosSelecionados.every((el) => el.situacao == "Pendente")
      );
    },
    podeSolicitarForcarConsultaStatus: function () {
      if (this.dadosSelecionados.length != 1) return false;

      return (
        this.dadosSelecionados[0].podeForcarAtualizacaoStatus &&
        this.permissao.editar
      );
    },
    podeRecomprarCobranca: function () {
      if (this.dadosSelecionados.length != 1) return false;

      return this.ehAdmin() && this.dadosSelecionados[0].podeRecomprarCobranca;
    },
    ehAdmin: function () {
      return this.$store.getters.ehAdmin;
    },
    podeResponder: function () {
      if (this.dadosSelecionados.length != 1) return false;

      return (
        this.dadosSelecionados[0].situacao == "PendenteCancelamento" &&
        this.ehAdmin
      );
    },
    podeRetentar: function () {
      if (this.dadosSelecionados.length != 1) return false;

      return this.dadosSelecionados[0].situacao == "Negado";
    },
    totalValor: function () {
      return helpers.formatarValor(
        this.form.cobrancas.reduce((total, el) => total + el.valor, 0)
      );
    },
    totalValorProcessamento: function () {
      return helpers.formatarValor(
        this.form.cobrancas.reduce(
          (total, el) => total + el.valorProcessamento,
          0
        )
      );
    },
    totalValorAntecipado: function () {
      return helpers.formatarValor(
        this.form.cobrancas.reduce(
          (total, el) => total + el.valorLiquidoAntecipacao,
          0
        )
      );
    },
    totalQProfValorDesagio: function () {
      return helpers.formatarValor(
        this.form.cobrancas.reduce(
          (total, el) => total + (el.qProfValorDesagio || 0),
          0
        )
      );
    },
    totalQProfValorTarifa: function () {
      return helpers.formatarValor(
        this.form.cobrancas.reduce(
          (total, el) => total + (el.qProfValorTarifa || 0),
          0
        )
      );
    },
    totalQProfValorAquisicao: function () {
      return helpers.formatarValor(
        this.form.cobrancas.reduce(
          (total, el) => total + (el.qProfValorAquisicao || 0),
          0
        )
      );
    },
  },
  watch: {
    dadosSelecionados: function () {
      if (this.dadosSelecionados.length == 1) {
        this.botaoAtivarInativar = this.dadosSelecionados[0].ativo;
      } else if (this.dadosSelecionados.length == 0) {
        this.botaoAtivarInativar = null;
      }
    },
  },
};
</script>
<style lang=""></style>
