<template lang="">
  <div>
    <b-tabs class="abas-formulario" card>
      <b-tab
        id="tab-detalhes"
        active
        :title="$t('ASSINATURAS.ABA_DETALHES')"
        class="p-0"
      >
        <detalhes
          @alterarCartao="alterarCartao()"
          ref="detalhes"
          :form="form"
          @emitir-plano="atualizarPlano"
        />
      </b-tab>
      <produtos id="tab-produtos" :plano="plano" />
      <cobrancas
        id="tab-cobrancas"
        :form="form"
        @atualizar="atualizarCobrancas"
      />
      <historico-transacoes :form="form"/>
      <!-- <historico-troca-cartoes :form="form"/> -->
      <solicitacoes
        id="tab-solicitacoes"
        ref="solicitacoes"
        :form="form"
        @atualizar="buscar"
      />
    </b-tabs>
  </div>
</template>
<script>
import { START_LOADING, STOP_LOADING } from "@/store/Store";
import { DEFINIR_BREADCRUMB } from "@/store/breadcrumbs.module";
import AssinaturasService from "@/common/services/assinaturas/assinaturas.service";
import Detalhes from "./components/Detalhes.vue";
import Cobrancas from "./components/Cobrancas.vue";
import HistoricoTransacoes from "./components/HistoricoTransacoes.vue";
// import HistoricoTrocaCartoes from "./components/HistoricoTrocaCartao.vue";
import Produtos from "./components/Produtos.vue";
import Solicitacoes from "./components/Solicitacoes.vue";
import helpers from "../../common/utils/helpers";
export default {
  props: ["id"],
  components: {
    Detalhes,
    Cobrancas,
    HistoricoTransacoes,
    // HistoricoTrocaCartoes,
    Produtos,
    Solicitacoes,
  },
  data: function () {
    return {
      form: {
        planoId: null,
        nome: null,
        quantidadeCobranca: 0,
        valor: 0,
        dataInicio: null,
        dataTermino: null,
        nomeAssinante: null,
        emailAssinante: null,
        cartaoAssinante: null,
        frequencia: "Mensal",
        situacao: "Ativa",
        cobrancas: [],
        solicitacoesCancelamento: [],
        liberarAntecipacaoRecorrencia: false,
      },
      plano: null,
    };
  },
  methods: {
    alterarCartao: function () {
      this.confirmar(
        this.$t(`ASSINATURAS.TITULO_SALVAR_CARTAO`),
        this.$t("ASSINATURAS.CONFIRMAR_SALVAR_CARTAO")
      ).then((confirmado) => {
        if (!confirmado) return;

        this.$store.dispatch(START_LOADING);
        AssinaturasService.alterarCartao({ assinaturaId: this.id })
          .then(() => {
            this.alertaSucesso(
              this.$t("ASSINATURAS.MENSAGEM_SUCESSO_CARTAO")
            ).then(() => {});
          })
          .finally(() => {
            this.$store.dispatch(STOP_LOADING);
          });
      });
    },
    cancelar: function () {
      this.$router.push({ name: "assinaturas" });
    },
    buscar: function () {
      this.$store.dispatch(START_LOADING);
      AssinaturasService.buscar(this.id)
        .then((res) => {
          this.form = res.data.data;
          this.form.cobrancas = this.form.cobrancas.sort(
            (a, b) => a.numeroParcela - b.numeroParcela
          );
          this.form.valor = helpers.formatarValor(this.form.valor);

          this.form.frequencia = helpers.getEnum(this.form.frequencia)?.text;
          this.form.situacao = helpers.getEnum(this.form.situacao)?.text;
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    atualizarCobrancas: function () {
      this.buscar();
      this.$refs.solicitacoes.buscarSolicitacoes();
    },
    atualizarPlano: function (plano) {
      this.plano = plano;
    },
  },
  mounted() {
    this.$store.dispatch(DEFINIR_BREADCRUMB, [
      { titulo: this.$t("ASSINATURAS.TITULO_LISTA"), to: "/assinaturas" },
      {
        titulo: this.$t(
          `ASSINATURAS.TITULO_FORMULARIO_${!this.id ? "NOVO" : "VISUALIZAR"}`
        ),
      },
    ]);

    this.form.estabelecimentoId =
      this.$store.state.autenticacao.estabelecimentoSelecionado;

    if (this.id) {
      this.buscar();
    }
  },
};
</script>
<style lang="scss"></style>
