<template>
  <b-tab :title="$t('ASSINATURAS.PRODUTOS')" class="p-0">
    <b-card>
      <div class="row">
        <valorem-tabela
          class="col-12 mt-2"
          :colunas="colunasProduto"
          v-model="tabelaProdutos"
          sem-paginacao
          rodape
        >
          <template #foot(nome)>
            <span>{{ $t("PLANOS.VALOR_TOTAL") }}</span>
          </template>
          <template #foot(valor)>
            <span>{{ valorTotal }}</span>
          </template>
          <template #foot()>
            <span></span>
          </template>
        </valorem-tabela>
      </div>
    </b-card>
  </b-tab>
</template>
<script>
import helpers from "@/common/utils/helpers";
export default {
  props: {
    plano: {},
  },
  components: {},
  data() {
    return {
      colunasProduto: [
        { key: "nome", label: this.$t("PLANOS.NOME_PRODUTO"), thClass: "w-50" },
        {
          key: "valor",
          label: this.$t("PLANOS.VALOR_PRODUTO"),
          formatter: (v) => {
            if (v >= 0) return helpers.formatarValor(v);
          },
        },
        { key: "quantidade", label: this.$t("PLANOS.QUANTIDADE_PRODUTO") },
      ],
    };
  },
  computed: {
    tabelaProdutos: function () {
      if (!this.plano) return [];

      let tabela = [];
      tabela.push(...this.plano.produtos);

      let valorPlano = this.plano.valorPlano;
      if (valorPlano > 0) {
        tabela.push({
          codigo: "-",
          produtoId: ".",
          nome: `Valor do plano`,
          quantidade: this.plano.parcelas,
          valor: valorPlano || 0,
        });
      }

      if (this.plano.aplicarTaxaExtra) {
        let valor = helpers.removerValor(`${this.plano.valorTaxa}`) / this.plano.parcelas;
        tabela.push({
          codigo: "-",
          produtoId: "-",
          nome: `(Taxa extra) - ${this.plano.descricaoTaxaExtra || "Sem descrição"} - ${
            this.plano.taxaExtraParcelada
              ? `Parcelado em ${this.plano.parcelas} vezes de ${helpers.formatarValor(
                  valor
                )}`
              : "À vista"
          }`,
          quantidade: 1,
          valor: helpers.removerValor(`${this.plano.valorTaxa}`) || 0,
        });
      }

      return tabela;
    },
    valorTotal: function () {
      let total = this.tabelaProdutos.reduce((total, produto) => {
        return total + produto.valor * produto.quantidade;
      }, 0);
      return helpers.formatarValor(total);
    },
    parcelas: function () {
      if (!this.plano) return [];

      let parcelas = [
        {
          value: 1,
          text: "À Vista",
        },
      ];
      for (let i = 2; i <= this.plano.quantidadeCobranca; i++) {
        let valor = helpers.removerValor(`${this.plano.valorTaxa}`);
        parcelas.push({
          value: i,
          text: `Parcelado em ${i} vezes de ${helpers.formatarValor(valor / i)}`,
        });
      }
      return parcelas;
    },
  },
  mounted() {},
};
</script>
<style lang=""></style>
