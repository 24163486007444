import ApiService from '@/common/api/api.service'

const AssinaturaCobrancaService = {
  solicitarCancelamento : function(form){
    return ApiService.put('assinatura-cobranca/solicitar-cancelamento', form)
  },
  responderSolicitacaoCancelamento : function(form){
    return ApiService.put('assinatura-cobranca/responder-solicitacao-cancelamento', form)    
  },
  buscarSolicitacao: function (id) {
    return ApiService.get(`assinatura-cobranca/solicitacao-pendente/${id}`)
  },
  retentarPagamento: function (id) {
    return ApiService.put(`assinatura-cobranca/retentar-pagamento/${id}`)
  },
  forcarAtualizacaoStatusCobrancaParcelada: function (id) {
    return ApiService.put(`assinatura-cobranca/forcar-atualizacao-status/${id}`)
  },
  recomprarCobranca: function (id) {
    return ApiService.put(`assinatura-cobranca/recomprar-cobranca/${id}`)
  },
}

export default AssinaturaCobrancaService
